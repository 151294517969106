import cx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import IconTextButton from '../../../../../../components/IconTextButton';
import { ApplicabilityTextLangMap } from '../../../../../../models/compareFeatures.model';
import ApplicabilityText from '../ApplicabilityText/ApplicabilityText';
import styles from './applicabilityTextPopover.module.scss';
import useAccessibleClick from '../../../../../../hooks/useAccessibleClick';

interface ModalContentProps {
  coords: { top: number; left: number };
  popoverElementClass?: string;
  wrapperRef: React.RefObject<HTMLDivElement>;
  applicabilityTextMap: ApplicabilityTextLangMap;
  grade: string;
  onUpdate: (text: ApplicabilityTextLangMap) => void;
  onClose: () => void;
}

const ModalContent = ({ coords, wrapperRef, applicabilityTextMap, grade, onUpdate, onClose, popoverElementClass }: ModalContentProps) => (
  <div className={styles.popoverContainer} style={{ color: 'red', top: `${coords.top}px`, left: `${coords.left}px` }}>
    {
      <div ref={wrapperRef} className={styles.popoverElementContainer}>
        <div
          className={cx(
            {
              [styles.popoverElement]: true,
              [styles.popoverDefault]: true,
            },
            styles[`align-left`],
            popoverElementClass,
          )}
        >
          <div>
            <ApplicabilityText applicabilityTextMap={applicabilityTextMap} grade={grade} onUpdate={onUpdate} closePopover={onClose} />
          </div>
        </div>
      </div>
    }
  </div>
);

interface Props {
  defaultApplicabilityText: string | JSX.Element;
  readOnly?: boolean;
  handleUpdateItem: (text: ApplicabilityTextLangMap) => void;
  textButtonClassName?: string;
  grade: string;
  applicabilityTextMap: ApplicabilityTextLangMap;
  popoverClass?: string;
  popoverElementClass?: string;
}

const ApplicabilityTextPopover = ({
  defaultApplicabilityText,
  grade,
  handleUpdateItem,
  popoverClass,
  popoverElementClass,
  textButtonClassName,
  readOnly,
  applicabilityTextMap,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [coords, setCoords] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && ref.current && !ref.current.contains(event.target)) {
        handleExitPosition();
      }
    };
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handlePosition = useCallback(() => {
    const rect = ref.current?.getBoundingClientRect();
    if (rect) {
      setCoords({ left: rect.x + rect.width / 2 - 5, top: rect.y + window.scrollY + 18 });
    }
    setOpen(true);
  }, []);

  const handlePositionSpanClick = useAccessibleClick(handlePosition, { disabled: readOnly });

  const handleExitPosition = () => {
    setOpen(false);
  };

  if (readOnly) {
    if (defaultApplicabilityText) {
      return <div className={styles.applicabilityText}>{defaultApplicabilityText}</div>;
    }
    return null;
  }

  return (
    <>
      <div ref={ref} className={cx([popoverClass, styles.popover])}>
        {React.cloneElement(
          defaultApplicabilityText ? (
            <div {...handlePositionSpanClick}>{defaultApplicabilityText}</div>
          ) : (
            <IconTextButton className={textButtonClassName} smallIcon icon={'plus'} text="Applicability Text" disabled={readOnly} onClick={!readOnly ? handlePosition : () => {}} />
          ),
        )}
      </div>
      {open
        ? ReactDOM.createPortal(
            <ModalContent
              coords={coords}
              wrapperRef={wrapperRef}
              applicabilityTextMap={applicabilityTextMap}
              grade={grade}
              onUpdate={handleUpdateItem}
              onClose={handleExitPosition}
              popoverElementClass={popoverElementClass}
            />,
            document.body,
          )
        : null}
    </>
  );
};

export default ApplicabilityTextPopover;
