import cx from 'clsx';
import React from 'react';
import styles from './checkbox.module.scss';

export type ThemeType = 'greenCell' | 'greenOutline' | 'redOutline' | 'grayOutline';

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  labelClassName?: string;
  bold?: boolean;
  wrap?: boolean;
  small?: boolean;
  theme?: ThemeType;
}

const Checkbox = ({ id, className, children, labelClassName, bold, wrap, small, theme, ...rest }: CheckboxProps) => {
  const getTheme = (): string => {
    switch (theme) {
      case 'redOutline': {
        return styles.redOutline;
      }
      case 'greenCell': {
        return styles.greenCell;
      }
      case 'greenOutline': {
        return styles.greenOutline;
      }
      case 'grayOutline': {
        return styles.grayOutline;
      }
      default: {
        return styles.default;
      }
    }
  };

  return (
    <div data-testid="checkbox" className={cx(styles.checkbox, getTheme(), wrap && styles.wrap, small && styles.small, className)}>
      <input id={id} type="checkbox" {...rest} />
      <label htmlFor={id} tabIndex={-1} className={cx(bold && styles.bold, labelClassName)}>
        {children}
      </label>
    </div>
  );
};

export default Checkbox;
