import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'vapi-ui-common';
import { SplitModelApplicabilityModalContext } from '.';
import useStores from '../../../../../../hooks/useStores';
import { BnpCategorySplit, BnPDeleteSplitRequest } from '../../../../../../models/buildAndPrice.model';
import { KeyValueType } from '../../../../../../models/common.model';
import { deleteCategorySplit } from '../../../../../../webservices/vehicleBnPApi';

const DeleteModal = () => {
  const context = useContext(SplitModelApplicabilityModalContext);

  const {
    userStore: { brand },
    teamStore: {
      team: { param: team },
    },
  } = useStores();

  const { seriesId, year } = useParams<{
    seriesId: string;
    year: string;
  }>();

  if (!context) {
    return null;
  }

  const { category, isSaving, split, lang, setIsSaving, onClose } = context;

  if (!split) {
    return null;
  }

  const { categoryValue, name, categoryId, setSplits, applicability: currentApplicability } = category;
  const { id: splitId } = split;

  const onDelete = async () => {
    if (!lang) {
      return;
    }

    setIsSaving(true);

    const payload: BnPDeleteSplitRequest = {
      name,
      category: categoryId,
      splitId,
    };

    try {
      const { data } = await deleteCategorySplit(brand, team, seriesId, year, lang, payload);
      const { applicability, splits } = data[name].categories[categoryId];

      const nSplits = Object.values(splits as KeyValueType<BnpCategorySplit>)
        .filter(({ isDeleted }) => !isDeleted)
        .reduce((spMap, sp) => ({ ...spMap, [sp.id]: sp }), {});

      setSplits(nSplits);
      // have to delete non applicable models from the returned applicability data (b/c on the backend all models are saved in a category's applicability even if they arent applicable)
      const newApplicability = applicability as KeyValueType<string>;
      const modelIds = Object.keys(newApplicability);
      for (const modelId of modelIds) {
        if (!currentApplicability[modelId]) {
          delete newApplicability[modelId];
        }
      }
      category.applicability = newApplicability;
      onClose();
    } catch (error) {
      setIsSaving(false);
    }
  };

  return (
    <>
      <ModalHeader onClose={onClose}>Delete Split</ModalHeader>
      <ModalBody>
        {'Do you want to delete the '}
        <b>{categoryValue}</b>
        {' split?'}
      </ModalBody>
      <ModalFooter>
        <Button variant="outline" onClick={onClose} disabled={isSaving}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onDelete} disabled={isSaving}>
          Delete
        </Button>
      </ModalFooter>
    </>
  );
};
export default DeleteModal;
