import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { useState } from 'react';
import { ActionItemModal, ActionItemType, Dropdown, DropdownContent, Modal } from 'vapi-ui-common';
import { TableCell } from '../../../../../components/Table';
import useComponentVisible from '../../../../../hooks/useComponentVisible';
import useAccessibleClick from '../../../../../hooks/useAccessibleClick';
import styles from './contextMenuCell.module.scss';

interface ContextMenuCellProps {
  description: string;
  itemType: ActionItemType;
  deleteItem?(): void;
  copyItem?(): void;
  splitItem?(): void;
  splitText?: string;
  deleteText?: string;
}

const ContextMenuCell = ({ description, itemType, deleteItem, copyItem, splitItem, splitText, deleteText = 'Delete' }: ContextMenuCellProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleOnCopy = useAccessibleClick(() => {
    setIsComponentVisible(false);
    copyItem && copyItem();
  });

  const handleOnDelete = useAccessibleClick(() => {
    setIsComponentVisible(false);
    deleteItem && setShowDeleteModal(true);
  });

  const handleOnSplit = useAccessibleClick(() => {
    setIsComponentVisible(false);
    splitItem && splitItem();
  });

  return (
    <TableCell>
      <div ref={ref}>
        <Dropdown>
          <FontAwesomeIcon className={cx(styles.dragIcon, isComponentVisible && styles.open)} icon={faEllipsisV} onClick={() => setIsComponentVisible(true)} />
          <DropdownContent open={isComponentVisible}>
            {copyItem && (
              <div className={styles.listItem} {...handleOnCopy}>
                Copy
              </div>
            )}
            {deleteItem && (
              <div className={styles.listItem} {...handleOnDelete}>
                {deleteText}
              </div>
            )}
            {splitItem && (
              <div className={styles.listItem} {...handleOnSplit}>
                {splitText}
              </div>
            )}
          </DropdownContent>
        </Dropdown>

        <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
          <ActionItemModal itemType={itemType} onConfirm={() => deleteItem && deleteItem()} description={description} onClose={() => setShowDeleteModal(false)} />
        </Modal>
      </div>
    </TableCell>
  );
};

export default ContextMenuCell;
