import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useComponentVisible from '../../../hooks/useComponentVisible';
import React from 'react';
import { Dropdown, DropdownContent } from 'vapi-ui-common';
import SortButton from '../SortButton';
import useAccessibleClick from '../../../hooks/useAccessibleClick';
import styles from './sortDropdown.module.scss';

interface DropdownItemProps {
  item: string;
  onSelect: (item: string) => void;
}

const DropdownItem = ({ item, onSelect }: DropdownItemProps) => {
  const handleSelect = useAccessibleClick(() => onSelect(item));

  return (
    <li {...handleSelect} data-testid="dropdown-item-comp">
      {item}
    </li>
  );
};

interface DropdownListProps {
  buttonText: string;
  list: Array<string>;
  onSelect: (item: string) => void;
}

const SortDropdown = ({ buttonText, list = [], onSelect = () => void 0 }: DropdownListProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleSelect = (item: string) => {
    onSelect(item);
    setIsComponentVisible(false);
  };

  return (
    <Dropdown>
      <SortButton toggled={isComponentVisible} onClick={() => setIsComponentVisible(true)}>
        {buttonText} <FontAwesomeIcon className={styles.caret} icon={faAngleDown} />
      </SortButton>
      <DropdownContent open={isComponentVisible} ref={ref}>
        <ul className={styles.list}>
          {list?.map(item => (
            <DropdownItem key={item} item={item} onSelect={handleSelect} />
          ))}
        </ul>
      </DropdownContent>
    </Dropdown>
  );
};

export default SortDropdown;
