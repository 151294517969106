import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ActionButton, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'vapi-ui-common';
import useStores from '../../../../../../../hooks/useStores';
import { BRAND_TDPR, Brand } from '../../../../../../../models/user.model';
import { VehicleDataVersionInfo, VehicleTeam } from '../../../../../../../models/vehicleData.model';
import { VehicleModelItem, VehicleModelToyota } from '../../../../../../../models/vehicleModel.model';
import ConfirmPublishModalTable from './ConfirmPublishModalTable';
import ConfirmPublishModalTableRow from './ConfirmPublishModalTableRow';
import styles from './confirmPublishModalTable.module.scss';

interface ConfirmPublishModalProps {
  headerText: string;
  onConfirm: () => void;
  confirmButtonText?: string;
  open: boolean;
  isDraft: boolean;
  onClose: () => void;
  team: VehicleTeam;
  seriesId: string;
  year: string;
  version?: number;
  versionInfo: VehicleDataVersionInfo;
  showModelDetails?: boolean;
}

const ConfirmPublishModal = observer(
  ({
    open,
    onClose,
    headerText,
    isDraft,
    onConfirm,
    team,
    seriesId,
    year,
    version,
    versionInfo,
    confirmButtonText = 'Certify & Publish',
    showModelDetails = true,
  }: ConfirmPublishModalProps) => {
    const {
      userStore: { brand },
      vehicleModelsStore,
      seriesSettingsStore,
      teamStore,
    } = useStores();

    const [showTable, setShowTable] = useState(false);
    const [models, setModels] = useState<VehicleModelItem<VehicleModelToyota>[]>([]);

    useEffect(() => {
      (async () => {
        if (!open) {
          return;
        }

        try {
          if (!isDraft && showModelDetails) {
            await vehicleModelsStore.fetchData({
              brand,
              team: teamStore.team.param,
              series: seriesId,
              year,
              versionInfo,
              languagePermissions: teamStore.team.langPermissions,
              defaultLanguage: teamStore.team.defaultLanguage,
            });
            setModels(vehicleModelsStore.vehicleModels.filter(model => brand !== BRAND_TDPR || model.getVal('isTDPR') || model.getVal('isUSVI')));

            await seriesSettingsStore.fetchData(
              brand as Brand,
              teamStore.team.param,
              seriesId,
              year,
              teamStore.team.defaultSeriesSettingsLanguage,
              teamStore.team.seriesSettingsLanguages,
              versionInfo,
            );
          }
        } catch (e) {
          console.log(e);
          toast.error('Failed loading model data');
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seriesSettingsStore, vehicleModelsStore, teamStore, setModels, brand, seriesId, version, year, isDraft, showModelDetails, open]);

    const codeRedModel = (model: VehicleModelItem<VehicleModelToyota>) => {
      return model.getVal('isNotPublishable') || seriesSettingsStore.codeRedFuelTypes[model.getVal('fuelType')?.id];
    };

    const noGoLiveDate = (model: VehicleModelItem<VehicleModelToyota>) => {
      if ((model.getVal('goLiveDate') === '' || model.getVal('goLiveDate') === null) && !codeRedModel(model)) {
        return true;
      }
      return false;
    };

    // check if model has go live date in past.
    const isLive = (model: VehicleModelItem<VehicleModelToyota>) => {
      if (model.getVal('goLiveDate') !== null && !codeRedModel(model)) {
        const ld = new Date(model.getVal('goLiveDate'));
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        if (ld <= now) {
          return true;
        }
      }
      return false;
    };

    // check if model has go live date in the future
    const isFutureDate = (model: VehicleModelItem<VehicleModelToyota>) => {
      if (model.getVal('goLiveDate') !== null && !codeRedModel(model)) {
        const ld = new Date(model.getVal('goLiveDate'));
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        if (ld > now) {
          return true;
        }
      }
      return false;
    };

    // count models with future dates
    const futureModelCount = models.filter(model => isFutureDate(model)).length;

    //count prelive models
    const isLiveCount = models.filter(model => isLive(model)).length;

    // count models that are code red
    const codeRedModelCount = models.filter(model => model.getVal('isNotPublishable') || seriesSettingsStore.codeRedFuelTypes[model.getVal('fuelType')?.id]).length;

    // count models with no go live date
    const noGoLiveDateCount = models.filter(model => noGoLiveDate(model)).length;

    return (
      <Modal open={open} onClose={onClose}>
        <ModalHeader onClose={onClose}>{headerText}</ModalHeader>
        <ModalBody>
          Are you sure you want to publish Version <span className={styles.boldLabel}>{` V${version}`}</span>
          {showModelDetails && (
            <>
              <>
                {/* Models with go live date in the past */}
                {isLiveCount >= 1 && (
                  <ul>
                    <li>
                      There are <span className={styles.modelNumbers}>{`${isLiveCount} model(s)`}</span> that are currently live.
                    </li>
                  </ul>
                )}
              </>
              <>
                {/* Models with go live date in futre */}
                {futureModelCount >= 1 && (
                  <ul>
                    <li>
                      There are <span className={styles.modelNumbers}>{`${futureModelCount} model(s)`}</span> that will launch on a future date.
                    </li>
                  </ul>
                )}
              </>
              <>
                {/* Code Red Models */}
                {codeRedModelCount >= 1 && (
                  <ul>
                    <li>
                      There are <span className={styles.modelNumbers}>{`${codeRedModelCount} code red model(s).`}</span>
                    </li>
                  </ul>
                )}
              </>
              <>
                {/* Models with no go live date set */}
                {noGoLiveDateCount >= 1 && (
                  <ul>
                    <li>
                      There are <span className={styles.modelNumbers}>{`${noGoLiveDateCount} model(s)`}</span> with no live date set.
                    </li>
                  </ul>
                )}
              </>
              <div className={styles.boldLabel}>
                Preview
                <ActionButton icon={showTable ? 'arrowDown' : 'arrowRight'} onClick={() => setShowTable(!showTable)} />
              </div>
              {showTable && (
                <ConfirmPublishModalTable
                  renderRows={() => (
                    <>
                      {models.map(model => (
                        <ConfirmPublishModalTableRow
                          brand={brand}
                          key={model.uid}
                          vehicleModel={model}
                          goLiveDate={model.getVal('goLiveDate')}
                          noGoLiveDate={noGoLiveDate(model) === true}
                          isLive={isLive(model) === true}
                          isFutureLive={isFutureDate(model) === true}
                          isCodeRed={model.getVal('isNotPublishable') || seriesSettingsStore.codeRedFuelTypes[model.getVal('fuelType')?.id]}
                        />
                      ))}
                    </>
                  )}
                />
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="transparent" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            {confirmButtonText}
          </Button>
        </ModalFooter>
      </Modal>
    );
  },
);

export default ConfirmPublishModal;
