import Axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { getToken } from '../authConfig';

const API: AxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_GRAPHQL_URL,
});

const requestHandler = async (request: InternalAxiosRequestConfig) => {
  const token = await getToken();
  if (request.headers) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
};

const responseHandler = (response: AxiosResponse<any>) => {
  return response;
};

const responseRejectedHandler = (error: AxiosError<{ message?: string }>) => {
  switch (error.config?.method || '') {
    case 'delete':
    case 'post':
    case 'put': {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error('An unknown error occurred');
      }
      break;
    }
  }
  return Promise.reject(error);
};

API.interceptors.request.use(requestHandler);
API.interceptors.response.use(responseHandler, responseRejectedHandler);

export default API;
