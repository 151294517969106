import React, { useState } from 'react';
import { ActionButton, RTEditorProps } from 'vapi-ui-common';
import useAccessibleClick from '../../../hooks/useAccessibleClick';
import styles from './dropdownEditItem.module.scss';

interface DropdownEditItemProps extends RTEditorProps {
  value: string;
  isSelected: boolean;
  onSelect: () => void;
  onEdit?: (oldValue: string, newValue: string) => void;
  onClose?: () => void;
}

const DropdownEditItem = ({ value, isSelected, onEdit, onSelect, onClose = () => void 0 }: DropdownEditItemProps) => {
  const [editOpen, setEditOpen] = useState(false);
  const [itemVal, setItemVal] = useState(value);
  const [inputVal, setInputVal] = useState(value);

  const handleOnEdit = () => {
    if (inputVal?.trim() && value !== inputVal.trim()) {
      setItemVal(inputVal.trim());
      onEdit && onEdit(value, inputVal.trim());
      setEditOpen(false);
    }
  };

  const handleSelect = useAccessibleClick(() => {
    onSelect();
    onClose();
  });

  return (
    <li className={isSelected ? styles.selected : ''}>
      {!editOpen ? (
        <>
          <div className={styles.value} {...handleSelect}>
            {itemVal}
          </div>
          {onEdit && (
            <div className={styles.editBtn}>
              <ActionButton
                icon="edit"
                onClick={() => {
                  setEditOpen(true);
                }}
              />
            </div>
          )}
        </>
      ) : (
        <div className={styles.inputGroup}>
          <input className={styles.input} type="text" value={inputVal} onChange={e => setInputVal(e.currentTarget.value)} />
          <button className={styles.inputBtn} onClick={() => handleOnEdit()}>
            Save
          </button>
        </div>
      )}
    </li>
  );
};

export default DropdownEditItem;
