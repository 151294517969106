import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styles from './tabs.module.scss';

// https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-1/tabs.html
type Props = {
  tabs: string[];
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  isTabDisabled?: (tab: string) => boolean;
};
const Tabs: React.FC<Props> = ({ tabs, selectedTab, setSelectedTab, isTabDisabled = () => false }) => {
  return (
    <div className={styles.root}>
      <div className={styles.tabs} role="tablist" aria-label="Entertainment">
        {tabs.map(tab => (
          <button
            disabled={isTabDisabled(tab)}
            key={tab}
            className={cn(styles.tab, {
              [styles.active]: tab === selectedTab,
            })}
            role="tab"
            aria-selected={tab === selectedTab}
            aria-controls={`${tab}-tabpanel`}
            id={tab}
            onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
    </div>
  );
};

type TabPanelProps = {
  tab: string;
  selected: boolean;
  children: React.ReactNode;
};
export const TabPanel: React.FC<TabPanelProps> = ({ children, selected, tab }) => (
  <div tabIndex={-1} role="tabpanel" id={`${tab}-tabpanel`} className={styles.tabPanel}>
    {selected && children}
  </div>
);

export default observer(Tabs);
