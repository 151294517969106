import React from 'react';

import Input from '../../../../../../../components/Input';

import styles from '../../../modelsModal.module.scss';

const HiddenField = () => {
  return (
    <div>
      <div className={styles.hide}>
        <span>hidden</span>
        <Input disabled />
      </div>
    </div>
  );
};

export default HiddenField;
