import { faFlag } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { ActionButton, ConfirmModal, Modal, NotesPopover } from 'vapi-ui-common';
import Checkbox from '../../../../../../components/Checkbox';
import { TableRowBase } from '../../../../../../components/Table/components/TableRow/TableRow';
import useStores from '../../../../../../hooks/useStores';
import { IDValueType } from '../../../../../../models/common.model';
import { RefItem } from '../../../../../../models/refItem.model';
import { SeriesSettingsItem } from '../../../../../../models/seriesSettings.model';
import { Language } from '../../../../../../models/user.model';
import { SeriesCategories, VehicleTeam } from '../../../../../../models/vehicleData.model';
import { VehicleModelItem, VehicleModelToyota } from '../../../../../../models/vehicleModel.model';
import { isModelValid } from '../../../../../../utils/modelsUtils';
import SyncTdPRButton from '../../../../components/SyncTdPRButton/SyncTdPRButton';
import styles from '../../modelsModal.module.scss';
import EditGoLiveDateModal from '../EditGoLiveDateModal';
import RemoveModelModal from '../RemoveModelModal';
import ModelsEditPanel from './ModelsModalEditPanel';
import useAccessibleClick from '../../../../../../hooks/useAccessibleClick';

interface ModelsRowProps {
  brand: string;
  index: number;
  category: SeriesCategories;
  model: VehicleModelItem<VehicleModelToyota>;
  grades: RefItem[];
  fuelTypes: IDValueType[];
  canAddDelete?: boolean;
  onShow?: (brand: string) => void;
  onSave?: (model: VehicleModelItem<VehicleModelToyota>, language?: Language) => void;
  onDelete?: (model: VehicleModelItem<VehicleModelToyota>) => void;
  onCopy?: (model: VehicleModelItem<VehicleModelToyota>) => void;
  onAddGrade?: (grade: string) => void;
  onUpdateGrade?: (gradeId: string, gradeValue: string) => void;
  readOnly?: boolean;
  seriesName: string;
  seriesSettings: SeriesSettingsItem[];
  canEditGoLiveDate: boolean;
  compareModel: (model: VehicleModelItem<VehicleModelToyota>) => void;
  editableLanguages: Language[];
}

const ModelsRow = ({
  brand,
  index,
  category,
  model,
  grades,
  fuelTypes,
  canAddDelete,
  onShow = () => void 0,
  onCopy = () => void 0,
  onSave = () => void 0,
  onDelete = () => void 0,
  onAddGrade = () => void 0,
  onUpdateGrade = () => void 0,
  readOnly,
  seriesName,
  seriesSettings,
  canEditGoLiveDate,
  compareModel,
  editableLanguages,
}: ModelsRowProps) => {
  const {
    seriesSettingsStore,
    teamStore,
    vehicleModelsStore: { defaultLanguage },
    modelTabStore: { getLangVehicleModel },
    userStore: { langPermissions },
  } = useStores();

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [isIconShowing, setisIconShowing] = useState(model.show);
  const [removeModel, setRemoveModel] = useState(false);
  const [editGoLiveDateModalOpen, setEditGoLiveDateModalOpen] = useState(false);
  const [confirmEditModal, setConfirmEditModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const defaultModel = getLangVehicleModel(model, defaultLanguage);

  const handleOnSave = (model: VehicleModelItem<VehicleModelToyota>, language?: Language) => {
    if (isCopy) {
      onCopy(model);
    } else {
      onSave(model, language);
    }
    setIsEditOpen(false);
  };

  const handleOnAddGrade = (grade: string) => {
    onAddGrade(grade);
  };

  const handleOnUpdateGrade = (id: string, val: string) => {
    onUpdateGrade(id, val);
  };

  const handleOnDelete = () => {
    onDelete(model);
  };

  const handleOnCopy = () => {
    setIsCopy(true);
    setIsEditOpen(true);
  };

  const handleOnEdit = () => {
    setIsCopy(false);
    setIsEditOpen(true);
  };

  const handleOnShow = () => {
    setisIconShowing(!model.show);
    onShow(brand);
  };

  const renderKatashikis = (katashiki: string) => {
    const katashikis = katashiki?.split('|') || [''];
    return (
      <>
        {katashikis.map((val, index) => (
          <p key={index}>{val}</p>
        ))}
      </>
    );
  };

  const setGoLiveDate = (goLiveDate: string) => {
    let isValid = true;
    const langModelMap: {
      [k in Language]?: VehicleModelItem<VehicleModelToyota>;
    } = {};
    for (const lang of editableLanguages) {
      const langModel = getLangVehicleModel(model, lang);
      if (isModelValid(langModel, langModel.getPayload(brand), brand)) {
        langModelMap[lang] = langModel;
      } else {
        isValid = false;
        break;
      }
    }
    if (isValid) {
      (Object.keys(langModelMap) as Language[]).forEach(lang => {
        const langModel = langModelMap[lang]!;
        langModel.updateDynamicProps({ goLiveDate });
        onSave(langModel, lang);
      });
    } else {
      toast.error('Please fill out all the required fields.');
    }
  };

  const goLiveDate = useCallback((): Date | null => {
    const gld = model.getVal('goLiveDate');
    return gld?.length ? new Date(gld) : null;
  }, [model]);

  const goLiveDateText = (): string => {
    const gld = goLiveDate();
    const noGoLiveDateText = readOnly || !canEditGoLiveDate ? '' : 'Set Go Live Date';
    return gld?.toLocaleDateString() ?? noGoLiveDateText;
  };

  const isGoLiveDateInPast = useCallback(() => {
    const gld = goLiveDate();
    if (gld != null) {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      if (gld < now) {
        return true;
      }
    }
    return false;
  }, [goLiveDate]);

  const canClickGoLiveDate = useCallback((): boolean => {
    return !readOnly && canEditGoLiveDate;
  }, [canEditGoLiveDate, readOnly]);

  const goLiveDateClass = useCallback((): string => {
    if (readOnly || !canEditGoLiveDate) {
      return isGoLiveDateInPast() ? styles.publishedPastGoLiveDate : styles.publishedGoLiveDate;
    }
    return styles.goLiveDate;
  }, [readOnly, canEditGoLiveDate, isGoLiveDateInPast]);

  const isCodeRed = (model: VehicleModelItem<VehicleModelToyota>) => {
    return seriesSettingsStore.codeRedFuelTypes[model.getVal('fuelType')?.id] ?? false;
  };

  const changedAttributes = () => {
    const changed: string[] = [];
    for (const lang of teamStore.team.languages) {
      if (!!langPermissions[lang]?.canEdit) {
        const changedAttr: string[] = getLangVehicleModel(model, lang).getVal('changedAttributes') ?? [];
        changed.push(...changedAttr);
      }
    }
    return changed;
  };

  const showSync = !!defaultModel.getVal('fromTMNA') && !readOnly;

  const handleGoLiveClick = useAccessibleClick(
    useCallback(() => {
      if (canClickGoLiveDate() && !isGoLiveDateInPast()) {
        setEditGoLiveDateModalOpen(true);
      } else if (canClickGoLiveDate() && isGoLiveDateInPast()) {
        setConfirmEditModal(true);
      }
    }, [canClickGoLiveDate, isGoLiveDateInPast]),
  );

  return !isEditOpen ? (
    <Draggable draggableId={model.uid} index={index}>
      {(provided, snapshot) => (
        <>
          <TableRowBase
            zebra
            hoverShadow
            className={cx(styles.modelRow, styles.modelDrawerWrapper)}
            innerRef={provided.innerRef}
            {...provided.draggableProps}
            style={provided.draggableProps.style}
          >
            <td className={styles.publishCol}>
              <div
                className={cx({
                  [styles.container]: !readOnly,
                  [styles.hasChanges]: showSync,
                  [styles.hasNotChanges]: !showSync,
                })}
              >
                {showSync && (
                  <SyncTdPRButton
                    id={model.id}
                    changedAttributes={changedAttributes()}
                    onClick={() => {
                      compareModel(model);
                    }}
                  />
                )}
                <Checkbox
                  id={`donotpublish-${model.uid}`}
                  className={styles.isPublishableChbox}
                  disabled={readOnly || isCodeRed(model) || !teamStore.team.allowCodeRed}
                  defaultChecked={model.getVal('isNotPublishable') || isCodeRed(model)}
                />
              </div>
            </td>
            <td className={styles.previewCol}>
              <ActionButton onClick={handleOnShow} icon={isIconShowing ? 'preview' : 'nopreview'} />
            </td>
            <td className={styles.trimCol}>{model.getVal('code')}</td>
            <td className={styles.descriptionCol}>
              {`${model.getVal('grade').value} ${model.getVal('description')}`}
              {model.isHybrid() && <FontAwesomeIcon className={styles.hybrid} icon={faFlag} />}
            </td>

            <td className={styles.goLiveDateCol}>
              <div {...handleGoLiveClick} className={goLiveDateClass()}>
                {goLiveDateText()}
              </div>
              <Modal open={editGoLiveDateModalOpen} onClose={() => setEditGoLiveDateModalOpen(false)}>
                <EditGoLiveDateModal
                  close={() => setEditGoLiveDateModalOpen(false)}
                  onSubmit={(goLiveDate: string) => {
                    setGoLiveDate(goLiveDate);
                  }}
                  currentGoLiveDate={goLiveDate()}
                />
              </Modal>
              <ConfirmModal
                headerText="Edit Live Date"
                bodyText="This model is already published and live, are you sure you want to change the date?"
                confirmButtonText={'Confirm'}
                open={confirmEditModal}
                onClose={() => setConfirmEditModal(false)}
                onConfirm={() => setEditGoLiveDateModalOpen(true)}
              />
            </td>

            <td className={styles.katashikiCol}>{renderKatashikis(model.getVal('katashiki'))}</td>
            <td className={styles.ctaCol}>
              {!readOnly && (
                <>
                  {model.getVal('rejectNotes') && (
                    <NotesPopover
                      notes={model.getVal('rejectNotes')}
                      icon="purpleCircle"
                      iconText=""
                      readOnly
                      handleUpdateItem={() => {}}
                      popoverClass={styles.rejectNotesPopover}
                    />
                  )}
                  <Modal open={removeModel} onClose={() => setRemoveModel(false)}>
                    <RemoveModelModal close={() => setRemoveModel(false)} onSubmit={() => handleOnDelete()} />
                  </Modal>
                  {canAddDelete && <ActionButton icon="trash" onClick={() => setRemoveModel(true)} />}
                  {canAddDelete && <ActionButton icon="copy" onClick={() => handleOnCopy()} />}
                  <ActionButton icon="edit" onClick={() => handleOnEdit()} />
                  {teamStore.team.name !== VehicleTeam.AGENCY_SPANISH && <ActionButton icon="arrows" {...provided.dragHandleProps} />}
                </>
              )}
              {readOnly && <ActionButton icon={showDetails ? 'arrowDown' : 'arrowRight'} onClick={() => setShowDetails(!showDetails)} />}
            </td>
          </TableRowBase>

          <ModelsEditPanel
            readOnly
            rowClass={showDetails ? '' : styles.hide}
            brand={brand}
            seriesName={seriesName}
            category={category}
            model={isCopy ? model.makeCopy() : model}
            grades={grades}
            fuelTypes={fuelTypes}
            onAddGrade={!teamStore.team.canAddFromDropdown ? undefined : grade => handleOnAddGrade(grade)}
            onUpdateGrade={(id, val) => handleOnUpdateGrade(id, val)}
            onClose={() => setIsEditOpen(false)}
            onSave={(model, language) => handleOnSave(model, language)}
            seriesSettings={seriesSettings}
            compareModel={compareModel}
          />
        </>
      )}
    </Draggable>
  ) : (
    <ModelsEditPanel
      brand={brand}
      seriesName={seriesName}
      category={category}
      model={isCopy ? model.makeCopy() : model}
      grades={grades}
      fuelTypes={fuelTypes}
      onAddGrade={!teamStore.team.canAddFromDropdown ? undefined : grade => handleOnAddGrade(grade)}
      onUpdateGrade={(id, val) => handleOnUpdateGrade(id, val)}
      onClose={() => setIsEditOpen(false)}
      onSave={(model, language) => handleOnSave(model, language)}
      seriesSettings={seriesSettings}
      compareModel={compareModel}
    />
  );
};

export default observer(ModelsRow);
